import { Global, css } from '@emotion/react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { graphql, useStaticQuery } from 'gatsby';
import { getImage } from 'gatsby-plugin-image';
import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';

import CookieBanner from './components/CookieBanner';
import Footer from './components/Footer/Footer';
import Header from './components/Header/Header';

import { COOKIES_CONSENT, isCookieSet, setCookie } from './utils/cookies';
import { FontWrapper } from './utils/font-wrapper';

import theme from './utils/theme';

function removeFocus() {
  document.activeElement.blur();
}

// eslint-disable-next-line react/prop-types
export function StyleLayout({ children }) {
  return (
    <FontWrapper>
      <Global
        styles={css`
          body {
            font-family: 'Agile', sans-serif;
            color: ${theme.black};
            background-color: transparent;
            font-weight: 300;
            font-smooth: antialiased;
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: antialiased;
          }
          /* setting 'font-size: 100%' lets user adjust font-size in browser settings*/
          /* BUT all font-sizes must be set using rem*/
          /* AND padding/margin/border etc must NOT be set with rem*/
          /* https://blog.usejournal.com/dont-use-rem-em-for-paddings-margins-and-more-94e19026b000*/
          html {
            font-size: 100%;
          }
        `}
      />
      {children}
    </FontWrapper>
  );
}

// eslint-disable-next-line react/prop-types
export function SharedElementsLayout({ children, path }) {
  const { cookie, close } = useBannerImages();

  const cookiesConsentSet = isCookieSet(COOKIES_CONSENT);

  const [displayCookieBanner, setDisplayCookieBanner] = useState(!cookiesConsentSet);

  const hideAndSetCookie = () => {
    setDisplayCookieBanner(false);
    setCookie(COOKIES_CONSENT, 1);
  };

  useEffect(() => {
    const links = document.querySelectorAll('a');
    links.forEach((link) => link.addEventListener('click', removeFocus));
    return () => {
      links.forEach((link) => link.removeEventListener('click', removeFocus));
    };
  }, [path]);

  return (
    <div css={siteWrapper}>
      <Helmet
        htmlAttributes={{
          lang: 'en',
        }}
      />

      <Header path={path} />
      <main role="main" id="main-content">
        {children}
      </main>
      {displayCookieBanner && (
        <CookieBanner
          closeIconImage={close}
          cookieIconImage={cookie}
          hideAndSetCookie={hideAndSetCookie}
        />
      )}
      <Footer />
    </div>
  );
}

const useBannerImages = () => {
  const imagesData = useStaticQuery(graphql`
    query {
      closeIcon: file(relativePath: { eq: "close-purple.png" }) {
        childImageSharp {
          gatsbyImageData
        }
      }
      cookieIcon: file(relativePath: { eq: "cookie.png" }) {
        childImageSharp {
          gatsbyImageData
        }
      }
    }
  `);

  const close = getImage(imagesData.closeIcon);
  const cookie = getImage(imagesData.cookieIcon);
  return { close, cookie };
};

const siteWrapper = css`
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  a {
    decoration: none;
    transition: color 0.6s ease-in-out;
    transition-timing-function: ease-in-out;
    &:hover {
      color: ${theme.lightPurple};
    }

    :focus {
      outline: solid ${theme.purple};
    }
  }
`;
