/** @jsx jsx */
import { jsx } from '@emotion/react';
import { Link } from 'gatsby';
import { Fragment, useEffect, useState } from 'react';

import { trackHeaderLoginClick, trackHeaderMenuClick } from '../../utils/appInsightsCustomEvents';

import BurgerMenu from '../Icons/BurgerMenu';
import CloseX from '../Icons/CloseX';

import Logo from '../Logo/Logo';
import SelectLink from '../SelectLink/SelectLink';
import StyledLink from '../StyledLink/StyledLink';

import * as style from './Header.style';

const getMenuOptions = () => {
  const menuItems = [];

  menuItems.push({ label: 'Moving to Pulse', to: '/moving-to-pulse' });

  if (process.env.GATSBY_HIDE_ACTIVATE_CARD_PAGE !== 'true') {
    menuItems.push({ label: 'Activate your card', to: '/activate-your-card' });
  }

  menuItems.push(
    { label: 'About', to: '/about-us/' },
    { label: 'FAQs', to: '/frequently-asked-questions' },
  );

  return menuItems;
};

const MENU_ITEMS = getMenuOptions();

const useIsScrolled = () => {
  const [scrolled, setScrolled] = useState(false);

  const checkIsScrolledDown = () => {
    const isScrolled = !!window.pageYOffset;
    setScrolled(isScrolled);
  };

  useEffect(() => {
    document.addEventListener('scroll', checkIsScrolledDown, { passive: true });
    return () => {
      window.removeEventListener('scroll', checkIsScrolledDown);
    };
  }, [setScrolled]);

  return scrolled;
};

const LoginButton = ({ scrolled, displayMobileMenu }) => (
  <StyledLink
    css={[style.loginButton, scrolled || displayMobileMenu ? style.linkDark : style.linkLight]}
    handleClick={trackHeaderLoginClick}
    to="https://portal.newdaycards.com/pulse/login"
    aria="Login"
  >
    Login
  </StyledLink>
);

const DesktopMenuItems = ({ scrolled }) => (
  <nav
    aria-label="desktop-navigation"
    className="d-none d-lg-flex"
    role="navigation"
    data-cy="header-links"
  >
    {MENU_ITEMS.map((item, i) => (
      <StyledLink
        handleClick={() => trackHeaderMenuClick(item.label)}
        key={i}
        css={[style.link, scrolled ? style.linkDark : style.linkLight]}
        to={item.to}
      >
        {item.label}
      </StyledLink>
    ))}
  </nav>
);

const MobileMenuIcon = ({ displayMobileMenu, toggleMobileMenu, scrolled }) => (
  <button
    id="toggleMobileMenuIcon"
    className="d-lg-none"
    css={[scrolled || displayMobileMenu ? style.mobileMenuButtonDark : style.mobileMenuButtonLight]}
    onClick={toggleMobileMenu}
  >
    {displayMobileMenu ? <CloseX /> : <BurgerMenu dark={scrolled} />}
  </button>
);

const MobileMenuItems = ({ toggleMobileMenu }) => (
  <Fragment>
    {MENU_ITEMS.map((item, i) => (
      <div key={i} css={style.mobileMenuItems} className="container container-fluid">
        <SelectLink
          handleClick={() => {
            trackHeaderMenuClick(item.label);
            toggleMobileMenu();
          }}
          to={item.to}
        >
          {item.label}
        </SelectLink>
      </div>
    ))}
  </Fragment>
);

const TopBarLinks = ({
  scrolled,
  displayMobileMenu,
  toggleMobileMenu,
  setDisplayMobileMenu,
  path,
}) => (
  <div
    className={'container container-fluid'}
    css={[scrolled ? style.darkTopBarLinks : style.lightTopBarLinks]}
  >
    <Link
      to={`${path}#main-content`}
      css={[style.skipToMainContent, scrolled && style.skipToMainContentScrolled]}
    >
      Skip to main content
    </Link>

    <SelectLink
      to="/"
      aria="Pulse logo"
      passedStyle={scrolled || displayMobileMenu ? style.logoDark : style.logoLight}
      handleClick={() => {
        setDisplayMobileMenu(false);
      }}
    >
      <Logo data-cy="logo" dark={scrolled || displayMobileMenu} />
    </SelectLink>

    <div className="col-auto ml-auto align-items-center d-flex pr-0">
      <DesktopMenuItems {...{ scrolled }} />

      <LoginButton {...{ scrolled, displayMobileMenu }} />

      <MobileMenuIcon {...{ scrolled, displayMobileMenu, toggleMobileMenu }} />
    </div>
  </div>
);

const MobileMenu = ({ toggleMobileMenu, displayMobileMenu, scrolled, setDisplayMobileMenu }) => (
  <div id="mobileMenu" css={style.mobileMenuWrapper} className="d-lg-none">
    <div css={style.mobileMenuHeader}>
      <TopBarLinks {...{ toggleMobileMenu, scrolled, displayMobileMenu, setDisplayMobileMenu }} />
    </div>
    <MobileMenuItems {...{ toggleMobileMenu }} />
  </div>
);

export default function Header({ path }) {
  const [displayMobileMenu, setDisplayMobileMenu] = useState(false);

  const scrolled = useIsScrolled();

  const toggleMobileMenu = () => {
    setDisplayMobileMenu(!displayMobileMenu);
  };

  return (
    <header css={style.headerWrapper}>
      <nav
        role="navigation"
        css={style.header}
        className={`${scrolled ? 'scrolled' : ''}  ${displayMobileMenu ? 'mobile-menu' : ''}`}
      >
        <TopBarLinks
          {...{ toggleMobileMenu, scrolled, displayMobileMenu, setDisplayMobileMenu, path }}
        />
      </nav>

      {displayMobileMenu && (
        <MobileMenu {...{ toggleMobileMenu, displayMobileMenu, setDisplayMobileMenu }} />
      )}
    </header>
  );
}
